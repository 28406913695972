import React, { useState, useEffect } from "react";
import Navbar from ".";
import Footer from "./footer";
import axios from "axios";
import { API } from "../config";

export default function ProductDetails() {
  const [product, setProduct] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [cart, setCart] = useState(0);
  const productId = window.location.href.split("/")[4];

  const listCartByUser = async () => {
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        // window.location.href = "/signin";
        return;
      }
      user = JSON.parse(user);
      const response = await axios.post(`${API}/cart/cart/user`, {
        userId: user._id,
        productId: product._id,
      });
      // console.log("response", response);
      if (response.data.cart) {
        setCart(response.data.cart.numberOfItems);
      }
    } catch (error) {
      console.log("error carts", error);
    }
  };

  listCartByUser();

  useEffect(() => {
    console.log("start========================");
    const getProduct = async () => {
      try {
        const response = await axios.get(`${API}/product/product/${productId}`);
        console.log("response:", response);
        console.log("images:", response.data.product.image);
        setProduct(response.data.product);
        setCurrentImage(response.data.product.image[0]);
      } catch (error) {
        console.log("error", error);
      }
    };
    getProduct();

    console.log("end========================");
  }, []);

  const addToCart = async (productId) => {
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        window.location.href = "/signin";
      }
      user = JSON.parse(user);
      const response = await axios.post(
        `${API}/cart/create`,
        {
          userId: user._id,
          productId,
          numberOfItems: 1,
          status: "PENDING",
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("response", response);
      await listCartByUser();
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  return (
    <>
      <Navbar />
      <section
        className="pb-5"
        style={{
          background: "white",
          borderTop: "5px",
          borderColor: "gray",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div className="border-top pt-5">
          <div className="row gx-5">
            <aside className="col-lg-6">
              <div className="border rounded-4 mb-3 d-flex justify-content-center">
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    margin: "auto",
                    maxHeight: "50vh",
                  }}
                  alt=""
                  className="rounded-4 fit object-fit-cover"
                  src={`${API}/images/${currentImage}`}
                />
              </div>
              <div className="d-flex justify-content-center mb-3">
                {product?.image?.map((image) => (
                  <div
                    key={image}
                    role="button"
                    className="border mx-1 rounded-2 item-thumb"
                    onClick={() => setCurrentImage(image)}
                  >
                    <img
                      className="rounded-1 object-fit-cover"
                      alt=""
                      src={`${API}/images/${image}`}
                      style={{ height: "50px", width: "50px" }}
                    />
                  </div>
                ))}
              </div>
            </aside>
            <main className="col-lg-6">
              <div className="ps-lg-3">
                <h4 className="title text-dark">{product?.name}</h4>

                <div className="mb-3">
                  <span className="h5">Price: {product?.price}frs</span>
                </div>

                {product?.promotionPrice && product?.promotionPrice !== 0 && (
                  <div className="mb-3">
                    <span className="h5">
                      Promotion price: {product?.promotionPrice}frs
                    </span>
                  </div>
                )}

                <p>{product?.description}</p>

                <hr />

                <div
                  className="btn btn-success shadow-0"
                  onClick={() => addToCart(product._id)}
                >
                  <i className="me-1 fa fa-shopping-basket"></i> Add to cart (
                  {cart})
                </div>
                <a
                  href="/cart"
                  className="btn btn-success shadow-0 ml-2"
                  onClick={() => addToCart(product._id)}
                >
                  Visit Cart
                </a>
              </div>
            </main>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
