import React from "react";
import "../App.css";
import { useState } from "react";
import { validateEmail } from "../Home/utils";
import { Link } from "react-router-dom";
import Footer from "../Home/footer";
import Navbar from "../Home";
import axios from "axios";
import { API } from "../config";
function ShopCreate() {
  const [shopName, setShopName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getIsFormValid = () => {
    return (
      shopName &&
      validateEmail(email) &&
      location &&
      phonenumber &&
      !isSubmitting
    );
  };

  const clearForm = () => {
    setShopName("");
    setEmail("");
    setLocation("");
    setPhoneNumber("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let user = localStorage.getItem("user");
    if (!user) {
      window.location.href = "/signin";
    } else {
      user = JSON.parse(user);
      console.log("user:", user);
      const storeVals = {
        name: shopName,
        location,
        phoneNumber: phonenumber,
        email,
        storeType: "Online Store",
        userId: user._id,
        token: user.originToken,
      };

      try {
        const result = await axios.post(`${API}/store/create`, storeVals, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        if (result.status === 201) {
          window.location.href = "/post";
        } else {
          setError(result.error.response.data.errors);
        }
      } catch (error) {
        console.log(error);
        setError(error.response.data.errors);
      }

      setIsSubmitting(false);
      clearForm();
    }
  };

  return (
    <>
      <Navbar />

      <div className="container-fluid ing sign">
        <div className="App2">
          <form onSubmit={handleSubmit}>
            <div className="formbs">
              <fieldset>
                <h2 className="formas h2">
                  <b>Create A Shop</b>
                </h2>
                <div className="Field">
                  <label className="labels">Shop Name</label>
                  <input
                    value={shopName}
                    onChange={(e) => {
                      setShopName(e.target.value);
                    }}
                    placeholder="Shop name"
                  />
                </div>
                <div className="Field">
                  <label className="labels">Location</label>
                  <input
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    placeholder="Shop Location"
                  />
                </div>
                <div className="Field">
                  <label className="labels">Email address</label>
                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email address"
                  />
                </div>
                <div className="Field">
                  <label className="labels">Phone Number</label>
                  <input
                    value={phonenumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    placeholder="Phone Number"
                  />
                </div>
                {error && (
                  <div style={{ color: "red", paddingBottom: ".1rem" }}>
                    {Object.keys(error).map((keyName, i) => (
                      <p>{error[keyName]}</p>
                    ))}
                  </div>
                )}
                <button
                  className="formbutss"
                  type="submit"
                  disabled={!getIsFormValid()}
                >
                  Create{" "}
                </button>{" "}
              </fieldset>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ShopCreate;
