import React, { useState, useEffect } from "react";
import "../App.css";
import Footer from "./footer";
import Navbar from ".";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
const Profile = () => {
  const [user, setUser] = useState(null);
  const [store, setStore] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          window.location.href = "/signin";
        } else {
          setUser(JSON.parse(user));
          const result = await axios.get(
            `${API}/store/user/${JSON.parse(user)._id}`
          );
          setStore(result.data.store[0]);
        }
      } catch (err) {}
    };
    getUser();
  }, []);

  const deleteAccount = async () => {
    try {
      await axios.post(
        `${API}/user/delete`,
        {
          id: user._id,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      localStorage.removeItem("user");
      window.location.href = "/";
      setIsDeleting(false);
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = async () => {
    try {
      await axios.get(`${API}/user/signout`);
      localStorage.removeItem("user");
      window.location.href = "/";
      setIsDeleting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Navbar />

      <section
        className="profile"
        style={{
          backgroundColor: "#f4f5f7",
          marginTop: "0rem",
          overflow: "hidden",
        }}
      >
        {user ? (
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-lg-6 mb-4 mb-lg-0">
                <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                  <div className="row g-0">
                    <div
                      className="col-md-4  gradient-custom text-center text-white"
                      style={{
                        borderTopLeftRadius: ".5rem",
                        borderBottomleftRadius: ".5rem",
                      }}
                    >
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                        alt="Avatar"
                        className="img-fluid my-5"
                        style={{ width: "80px" }}
                      />
                      <h5>
                        {user.firstname} {user.lastname}
                      </h5>
                      <p className="capitalize">{user.role}</p>

                      <div className="d-flex justify-content-center mb-5">
                        <Link to="/edit">
                          <button
                            type="button"
                            data-mdb-button-init
                            data-mdb-ripple-init
                            className="btn btn-outline-light text"
                          >
                            Edit
                          </button>
                        </Link>
                        <button
                          type="button"
                          className="btn btn-danger ms-1"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body p-4">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <h6>Information</h6>
                          <button
                            className="btn btn-danger"
                            disabled={isDeleting}
                            onClick={() => signOut()}
                          >
                            Sign out
                          </button>
                        </div>
                        <hr className="mt-0 mb-4" />
                        <div className="row pt-1">
                          <div className="col-6 mb-3">
                            <h6>Email</h6>
                            <p className="text-muted">{user?.email}</p>
                          </div>
                          <div className="col-6 mb-3">
                            <h6>Phone</h6>
                            <p className="text-muted">{user?.phoneNumber}</p>
                          </div>
                        </div>
                        <h6>Others</h6>
                        <hr className="mt-0 mb-4" />
                        <div className="row pt-1">
                          <div className="col-6 mb-3">
                            <h6>Location</h6>
                            <p className="text-muted">{user?.location}</p>
                          </div>
                          <div className="col-6 mb-3">
                            <h6>Region</h6>
                            <p className="text-muted">{user?.region}</p>
                          </div>
                        </div>
                        <div className="md:d-inline-flex justify-content-start">
                          {user.role === "seller" && (
                            <div
                              className="mr-2"
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              <Link to="/payments" className="">
                                <button
                                  type="button"
                                  className="btn btn-success text"
                                >
                                  Payment details
                                </button>
                              </Link>
                            </div>
                          )}
                          <span className="mr-2">
                            <Link to="/my-orders">
                              <button
                                type="button"
                                className="btn btn-success text "
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                View your order
                              </button>
                            </Link>
                          </span>

                          {user.role === "seller" && store && (
                            <span className="">
                              <Link to="/verify-store">
                                <button
                                  type="button"
                                  className="btn btn-success text"
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  Verify store
                                </button>
                              </Link>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-lg-6 mb-4 mb-lg-0">
                <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                  <div className="row g-0">
                    <div className="col-md-8">
                      <div className="card-body p-4">
                        <h5
                          style={{
                            marginBottom: "1rem",
                          }}
                        >
                          No user found
                        </h5>
                        <Link to="/signin">
                          <button
                            className="btn"
                            style={{
                              width: "8rem",
                              padding: ".3rem rem",
                              background: "green",
                              color: "white",
                              height: "2.5rem",
                            }}
                          >
                            <b
                              style={{
                                color: "white",
                              }}
                            >
                              SignIn
                            </b>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Delete Account
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete your account?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => deleteAccount()}
                type="button"
                className="btn btn-danger"
                disabled={isDeleting}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Profile;
